<template>
  <div class='template'>    
      <div class='top'>        
        <article class="images">            
            <transition name="fade" appear>
                <div :style="{backgroundImage:`url(${page?.background})`}" />         
            </transition>
        </article>    
        <div class="fixed-stats" v-if="stats.length > 0">    
            <div v-for="stat in stats" :key="stat.label">
                <p class="value">{{stat.value}}{{stat.symbol}}</p>
                <p class="label">{{stat.label}}</p>
            </div>                        
        </div>  
      </div>
      <article class='text-content'>
          <transition name="slide" appear>
            <div>
                <div>
                    <h1>{{page.title}}</h1>
                    <AttachmentUI :page="page" :gallery="gallery" @showGallery="galleryVisible = true" @showVideo="showVideo"/>
                </div>
                <Editor @click="activateEditor" plugins="lists" api-key="2uyqbbnykquw7zpsd3ht43lzwe4s4dyu3l8x6p7vgvx8v4ug" :init="{menubar:false}" toolbar='[ undo redo | bold italic underline | fontsize forecolor | bullist numlist link ]' v-model="copy" class="copy" v-if="copy || $store.state.devMode" inline :disabled="!editorFocused" @change="updateContent"/>                        
            </div>
          </transition>
          <div class="linear-holder">
                <LinearButton :to="page?.previous" previous/>
                <LinearButton :to="page?.next"/>
          </div> 
      </article> 
      <transition name="fade">
        <Gallery :images="gallery" @close="galleryVisible = false" v-show="galleryVisible"/>
      </transition>
      <transition name="fade">
        <Gallery videoMode :images="[]" :videoId="videoId" @close="videoVisible = false" v-if="videoVisible" :show="videoVisible"/>
      </transition>
  </div>  
</template>

<script>
import Page from '@/mixins/Page';
import AttachmentUI from '@/components/AttachmentUI';
import LinearButton from '@/components/LinearButton';
import ImageTitle from '@/components/ImageTitle';
import Gallery from '@/components/Gallery';
export default {
    name:'Infographic Row',
    mixins: [Page],
    props: {
        page:Object
    },
    components:{
        AttachmentUI,
        Gallery,
        LinearButton,
        ImageTitle
    },
    data() {
        return {
            galleryVisible:false,
            videoVisible:false,
            imagesFromGallery:0                 
        }
    },
    computed: {        
        gallery() {
            let images = [];        
            for (let index = this.imagesFromGallery; index < this.page.gallery?.length; index++) {
                const element = this.page?.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        },
        copy() {
            let copy = this.page?.copy;            
            return copy.replace(/\[.*?\]/g, "");
        },
        stats() {
            let stats = this.parseShortCode(this.page?.copy)
            return stats.slice(0, 3);   //limits up to 3
        }
    },
    methods : {
        parseShortCode(shortCode) {
            const shortcodes = [];     
            const rawShortCodes = shortCode.match(/[\w-]+=".+?"/g);        
            let elementsInGroup = 3;          
            for (let index = 0; index < rawShortCodes?.length; index += elementsInGroup) {
                elementsInGroup = 3;
                const shortcode = {};
                const element1 = rawShortCodes[index].match(/([\w-]+)="(.+?)"/);
                const element2 = rawShortCodes[index + 1].match(/([\w-]+)="(.+?)"/);
                const element3 = rawShortCodes[index + 2]?.match(/([\w-]+)="(.+?)"/) ?? [];
                shortcode[element1[1]] = element1[2];
                shortcode[element2[1]] = element2[2];
                if( element3[1] !== 'symbol') {
                    elementsInGroup = 2;
                    shortcode['symbol'] = '%';
                } else {
                    shortcode[element3[1]] = element3[2] === '-' ? '': unescape(element3[2]);
                }
                shortcodes.push(shortcode);
            }                        
            return shortcodes;
        }
    }
}
</script>

<style lang="scss" scoped>
    @media (max-width:699px) {  
        .linear-holder {
            position: relative;
            height:90px;
        }
    }
    .gallery {
        z-index: 1;
    }
    .template {
        display:grid;
        align-items: center;   
        position: relative;                    
        background:url('~@/assets/images/bg_texture.jpg');  
        background-size: cover;        
        box-sizing: border-box;
        @media (max-width:899px) {            
            grid-template-rows: minmax(200px, 50vh) minmax(50vh, max-content);
        }   
        @media (min-width:900px) {
            padding:25px;
            padding:130px;
            gap:25px;
            grid-template-rows: 1fr 300px;            
        }                   
    }    
    .top {
        height:100%;
        position: relative;
    }
    .text-content {
        box-sizing: border-box;
        color: #F5F5F5;
        //display: grid;        
        height:100%;
        position: relative;
        > div > div:not(.copy) {
            padding-left:60px;            
            padding-right:60px;
        }
        @media (min-width:700px) {
            //place-items: center;
            > div:not(.linear-holder) {
                display:grid;
                grid-template-columns: 1fr 1fr;    
                gap:30px;
                height:100%;
                > div:not(.copy) {
                    padding-top:15px;
                    max-width: 550px;
                    padding-right:0;
                    margin-left:auto;
                    width:100%;
                }
            
            }
        }
        @media print {
            > div {
                grid-template-columns: max-content 1fr;
                .copy {
                    padding-right:0;
                }
                div:not(.copy) {
                    margin-left:0;
                }
            }
        }                    
    }
    h1 {
        padding-top:20px;
        color: #F5F5F5;
        text-transform: uppercase;
        padding-bottom:20px;
        border-bottom:1px solid #0066CC;
        max-width: 550px;    
        font-size: 2.125rem;    
    }
    .copy {
        padding:60px;
        max-width: 550px;
         @media (min-width:700px) {
            //max-height: calc(50vh - 175px);
            overflow: auto;
        }
    }
    .images {        
        display:grid;
        gap:1px;               
        height:100%;  
        width:100%;  
        position: relative;      
        &:before, &:after {
            position: absolute;
            bottom:-15px;
            content: "";
            width:25%;
            background:url('~@/assets/images/texture_black-asphalt.jpg');       
            background-size: cover;
            background-position: center; 
            height:80%;
        }                
        &:before {
            left:-15px;
        }
        &:after {
            right:-15px;
        }
        div {
            -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
            color-adjust: exact;     
            background-repeat:no-repeat;
            background-size: cover;
            background-position: center;                                                
            position: relative;
            z-index: 1;
            &:after {
                position: absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                background: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 100%);
                content: "";
            }
        }                  
    }   
    .fixed-stats {
        display: flex;
        justify-content: center;
        align-items: center;
        gap:45px;    
        padding:0 125px;             
        position: absolute;    
        top:0;
        left:0;
        width:100%;
        height:100%;
        box-sizing: border-box;
        z-index: 1;
        > div {
            display: flex;
            flex-direction: column;
            gap:5px;
            justify-content: space-between;
            position: relative;
            user-select: none;            
        }
        p {
            margin:0;
        }
        .value {
            color: #F5F5F5;
            font-size: 10vw;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 1;
            font-family: 'Helvetica Neue LT Std';
            font-weight: 900;            
            @media (min-width:800px) {
                font-size: 8rem;
            }
        }
        .label {
            font-family: 'Helvetica Neue LT Std';
            font-weight: bold;
            text-transform: uppercase;
            color: #F5F5F5;
            font-size: 1rem;
            letter-spacing: 0;
            line-height: 1;
        }
    }
</style>